<template>
    <div class="state-container" ref="stateSelector" :class="{ isOpen: isSelectorOpen, 'access-denied': !isSelectorOpen }" @click="openSelector">
        <!-- <div class="current-state" v-if="Object.values(allStates).length > 0" :class="{ isOpen: isSelectorOpen }" :style="[{ backgroundColor: hexToRgbA(allStates[currentState].color) }, { borderColor: allStates[currentState].color }]"> -->
        <div class="current-state" v-if="Object.values(allStates).length > 0" :class="{ isOpen: isSelectorOpen }">
            <div class="ball" v-if="allStates[currentStatusId].id" :style="{ backgroundColor: allStates[currentStatusId].id == 1 ? '#0F6437': (allStates[currentStatusId].id == 2 ? '#DBA600' : '') }"></div>
            <div class="name">
                {{ allStates[currentStatusId].name }}
            </div>
        </div>
        <div class="state-selector" v-if="isSelectorOpen" :class="{ active: isSelectorOpen }">
            <div class="title-text">{{ $t('issues.change_state_to') }}</div>
            <div
                class="state"
                v-for="(state, index) in sortedStates"
                @click="changeToState(state.id)"
                :key="index"
                :class="{ selected: currentStatusId == state.id }"
            >
                <div class="ball" :style="{ backgroundColor: state.id == 1 ? '#0F6437': (state.id == 2 ? '#DBA600' : '')}"></div>
                <div class="name">
                    {{ state.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        currentState: {
            type: undefined,
            default: false
        },
        auditId: {
            type: undefined,
            default: false
        }
    },
    name: 'states',
    data() {
        return {
            isSelectorOpen: false,
            currentStatusId: null
        }
    },
    computed: {
        allStates() {
            return this.$store.getters['audits/getFilterStates']()
        },
        sortedStates() {
            const arrFilter = Object.values(this.$store.getters['audits/getFilterStates']()).sort((status1, status2) => (status1.type > status2.type ? 1 : -1))
            return arrFilter
        },
        user(){
            return this.$store.getters['loginUser/getLocalUser']
        }
    },
    methods: {
        closeBoxes(e) {
            if (!this.$refs['stateSelector'].contains(e.target)) {
                this.isSelectorOpen = false
            }
        },
        openSelector() {
            //Check lvl of employee
            if(this.user && this.user.rol){
                const rol = this.$store.getters['employee/getRoles'](this.user.rol)
                if(rol && rol.level >= 7){
                    this.isSelectorOpen = !this.isSelectorOpen
                }
            }
        },
        hexToRgbA(hex) {
            var c
            if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
                c = hex.substring(1).split('')
                if (c.length == 3) {
                    c = [c[0], c[0], c[1], c[1], c[2], c[2]]
                }
                c = '0x' + c.join('')
                return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.1)'
            }
            throw new Error('Bad Hex')
        },
        changeToState(status) {
            var self = this
            if (status == 1) {
                this.$popup.confirm({
                    message: this.$t('popup.confirm.audit_state_change', { name: self.allStates[status].name }),
                    textSave: this.$t('popup.select.text_save'),
                    callSave: function () {
                        self.$store.dispatch('audits/changeState', { checklist: self.auditId, status: status }).then(function () {
                            self.currentStatusId = status
                            self.$popup.close()
                            self.isSelectorOpen = false
                        })
                    },
                    callCancel: function () {
                        self.$popup.close()
                        self.isSelectorOpen = false
                    }
                })
            } else {
                self.$store.dispatch('audits/changeState', { checklist: self.auditId, status: status }).then(function () {
                    self.currentStatusId = status
                    self.$popup.close()
                    self.isSelectorOpen = false
                })
            }
        }
    },
    created() {
        this.currentStatusId = this.$store.getters['audits/getSingleItemStatusId'](this.auditId)
    },
    updated() {
        // START LISTENING CLICKS WHEN UBICATION DIV IS OPENED
        if (this.isSelectorOpen) {
            document.addEventListener('click', this.closeBoxes)
        }
        // STOP LISTENING CLICKS WHEN UBICATION DIV IS CLOSED
        else {
            document.removeEventListener('click', this.closeBoxes)
        }
    },
    beforeDestroy() {
        // ENSURE STOP LISTENING CLICKS WHEN MENU TOP IS DESTROYED
        document.removeEventListener('click', this.closeBoxes)
    }
}
</script>

<style lang="scss">
.state-container {
    position: relative;
    width: 100%;

    .current-state {
        @include display-flex();
        @include align-items(center);
        @include border-radius(4px);
        @include background($image: img('down_caret_dark.svg'), $position: right 6px center, $size: 6px);
        border: 0.5px solid;
        padding: 6px;
        cursor: pointer;
        max-width: 150px;
        border: 1px solid $color-neutral-300;
        height: 30px;
        background-color: $color-white;
        .ball {
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
            border-radius: 50%;
            margin-right: 8px;
        }
        .name {
            @include font-size(xs);
            @include text-ellipsis();
            color: $color-black;
            max-width: 100px;
            font-family: $text;
            padding-right: 10px;
        }
        &.isOpen {
            @include background($image: img('up_caret_dark.svg'), $position: right 6px center, $size: 6px);
        }
    }


    .state-selector {
        @include border-radius(3px);
        top: 5px;
        position: relative;
        background-color: #fff;
        min-width: 250px;
        box-shadow: 0px 0px 10px 0px rgb(117 115 119 / 30%);
        z-index: 1;
        padding-bottom: 6px;

        .title-text {
            @include font-size(xs);
            padding: 12px;
            font-family: $text;
            color: $color-black;
        }
        .state {
            width: 100%;
            @include display-flex();
            @include align-items(center);
            @include border-radius(2px);
            padding: 6px;
            margin-right: 8px;
            cursor: pointer;
            .ball {
                width: 10px;
                height: 10px;
                min-width: 10px;
                min-height: 10px;
                border-radius: 50%;
                margin-right: 8px;
                margin-left: 14px;
            }
            .name {
                @include font-size(xs);
                @include text-ellipsis();
                color: $color-black;
                max-width: 100px;
                font-family: $text-bold;
            }
            &:hover {
                @include bgHover(#fff);
            }
            &.selected {
                background-color: $default-bg-color;
                @include background($image: img('tick_dark.svg'), $position: right 12px center, $size: 12px);
                &:hover {
                    background: $default-bg-color;
                    @include background($image: img('tick_dark.svg'), $position: right 12px center, $size: 12px);
                }
            }
        }
    }
}

.report .state-container {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 8px;

    @media (max-width: 767px) {
        justify-content: flex-start;
        padding-top: 8px;
    }

    .state-selector {
        position: absolute !important;
        top: 38px;
    }
}
</style>
