<template>
    <div id="content" class="report report-audit report-page" :class="[$mq, { menuOpen: !isExpanded && userIsLogged }]">
        <!-- mobile top buttons -->
        <div v-if="isMobile" class="btn-actions-mobile">
            <div class="left-buttons">
                <button class="icon-button white-button" v-if="isReportLogout" @click="goBack" :aria-label="$t('button.back')">
                    <img src="img/icons/back_icon.svg" />
                </button>
            </div>

            <div class="search-bar-container">
                <SearchBar
                    v-if="isMobileSearchBarOpen"
                    :showNoResultsMsg="showNoResultsMsg"
                    @searchInputChange="updateSearchValue"
                    @magnifying-glass="
                        () => {
                            isMobileSearchBarOpen = false
                        }
                    "
                />
                <button v-else @click="() => (isMobileSearchBarOpen = true)" class="icon-button white-button" :aria-label="$t('menu_aux.action.search')">
                    <img src="img/icons/search-button-icon-black.svg" />
                </button>
            </div>

            <div class="right-buttons">
                <!-- Share button -->
                <ShareURL />
                <!-- Print button -->
                <button class="icon-button blue-button" @click="handleSaveAsPDF" :aria-label="$t('nvgt.report.print')">
                    <img src="img/icons/print_pdf.svg" />
                </button>
                <!-- Open Display Options Sidebar button -->
                <button class="icon-button grey-button" @click="isRightSidebarOpen = true">
                    <img src="img/icons/back_icon.svg" />
                </button>
            </div>
        </div>

        <!-- landscape top buttons (tablets and desktop) -->
        <div v-else class="btn-actions">
            <div class="left-buttons hide">
                <Button v-if="isReportLogout" bType="back" :bCallback="goBack" />
            </div>
            <div class="bar-actions hide">
                <ShareURL />
                <Button bType="print" :bCallback="handleSaveAsPDF" bLabel="nvgt.report.print" />
            </div>
        </div>
        <div v-if="itemsWithActionPlans() > 0" class="nav-tabs" :style="{ left: navTabsLeftStyle }">
            <div class="tabs">
                <button class="tab" v-on:click="selectTab('audit')" :class="{ selected: selectedTab === 'audit' }">
                    <span class="title" :class="{ selected: selectedTab === 'audit' }">{{ $t('nvgt.report.audit_tab') }}</span>
                    <div class="text" :class="{ selected: selectedTab === 'audit' }">
                        <span v-if="Object.values(items)[0].resume.ev" class="status" :class="{ selected: selectedTab === 'audit' }">
                            {{ Object.values(items)[0].resume.ev.n }}
                        </span>
                        <span class="status" :class="{ selected: selectedTab === 'audit' }"> ({{ Object.values(items)[0].score }}%) </span>
                    </div>
                </button>
                <button class="tab action-plan" v-on:click="selectTab('actionPlan')" :class="{ selected: selectedTab === 'actionPlan' }">
                    <span class="action-plan-title">
                        <span class="title" :class="{ selected: selectedTab === 'actionPlan' }">{{ $t('nvgt.report.actionPlan_tab') }}</span>
                        <span class="items-number">
                            {{ itemsWithActionPlans() > 99 ? '+99' : itemsWithActionPlans() }}
                        </span>
                    </span>
                    <span class="text" :class="{ selected: selectedTab === 'actionPlan' }">
                        <!-- TO-DO -->
                        <span class="status" :class="{ selected: selectedTab === 'actionPlan' }">
                            {{ getReportStatus(Object.values(this.items)[0].data.actionplans_score) }}
                        </span>
                        <span class="status" :class="{ selected: selectedTab === 'actionPlan' }">
                            ({{ Object.values(this.items)[0].data.actionplans_score }}%)
                        </span>
                    </span>
                </button>
            </div>
        </div>

        <!-- report main container -->
        <div class="report-main-container" :class="[$mq]" :style="{ top: itemsWithActionPlans() > 0 ? '120px' : '73px' }">
            <div class="left-container">
                <div v-if="Object.values(items).length > 1" class="sidebar-left" :class="{ closedLeftSidebar: !isLeftSidebarOpen }">
                    <div class="sidebar-left-data-table">
                        <v-data-table
                            v-if="isLeftSidebarOpen"
                            :headers="headers"
                            fixed-header
                            :items="Object.values(items)"
                            hide-default-footer
                            :page.sync="pagination.page"
                            :items-per-page="pagination.itemsPerPage"
                            @page-count="pageCount = $event"
                        >
                            <template #item.title="item">
                                <div class="table-row" @click="changeReport(item.item.id)">
                                    <div class="top">
                                        <span class="title-audit" v-tooltip="{ content: item && item.item && item.item.title ? item.item.title : '' }">
                                            {{ item.item.title }}
                                        </span>
                                        <span class="score">{{ item.item.completed }}/{{ item.item.total }} ({{ item.item.score }}%)</span>
                                        <span v-if="item.item.delay" class="delay">{{ leftTime(item.item.delay) }}</span>
                                    </div>

                                    <div class="bottom">
                                        <span class="localization">{{ item.item.location_name }}</span>
                                        <span class="date" v-html="spanishDateFormat(item.item.created_date)"></span>
                                    </div>
                                </div>
                            </template>
                        </v-data-table>
                        <!-- <div id="pagination" v-if="!(noChanges && items.length == 0)"> TODO REVIEW "noChanges" -->
                        <div id="pagination" v-if="!(items.length == 0)">
                            <v-pagination v-model="pagination.page" color="#2c52c4" :length="pageCount" :total-visible="maxPageVisibles"></v-pagination>
                        </div>
                    </div>
                </div>
                <Button
                    v-if="Object.values(items).length > 1"
                    class="leftSiderbar-Controler"
                    :class="{ closedSidebar: !isLeftSidebarOpen }"
                    :bType="isLeftSidebarOpen ? 'leftArrow' : 'rightArrow'"
                    :bCallback="toggleLeftSidebar"
                >
                </Button>
            </div>
            <div class="main-content" :class="{ fullwidth: Object.values(items).length == 1, 'small-width': Object.values(items).length > 1 }">
                <template v-if="items[$route.params.id] && items[$route.params.id].items && itemsWithActionPlans() > 0">
                    <Report
                        v-if="selectedTab === 'audit'"
                        :data="items[$route.params.id]"
                        ref="reportPrint"
                        :showAnswers="showAnswers"
                        :showDetails="showDetails"
                        :showNoApply="showNoApply"
                        :printOpts="printOpts"
                        :previewOpts="previewOpts"
                        :reportType="2"
                        :showImagesResume="showImagesResume"
                        :showNotes="showNotes"
                        :showPoint="showPoint"
                        :userSearch="userFilterSearch"
                        @toggleNoResultsMsg="handleToggleNoResultsMsg"
                    />
                    <div v-else-if="selectedTab === 'actionPlan'">
                        <!-- ACTION PLAN REPORT -->
                        <ReportActionPlan
                            :data="items[$route.params.id]"
                            ref="actionPlanReport"
                            :showAnswers="showAnswers"
                            :showDetails="showDetails"
                            :showNoApply="showNoApply"
                            :printOpts="printOpts"
                            :previewOpts="previewOpts"
                            :reportType="2"
                            :showImagesResume="showImagesResume"
                            :showNotes="showNotes"
                            :showPoint="showPoint"
                            :userSearch="userFilterSearch"
                            @toggleNoResultsMsg="handleToggleNoResultsMsg"
                        />
                    </div>
                </template>
                <template v-else-if="items[$route.params.id] && items[$route.params.id].items && itemsWithActionPlans() < 1">
                    <Report
                        :data="items[$route.params.id]"
                        ref="reportPrint"
                        :showAnswers="showAnswers"
                        :showDetails="showDetails"
                        :showNoApply="showNoApply"
                        :printOpts="printOpts"
                        :previewOpts="previewOpts"
                        :reportType="2"
                        :showImagesResume="showImagesResume"
                        :showNotes="showNotes"
                        :showPoint="showPoint"
                        :userSearch="userFilterSearch"
                        @toggleNoResultsMsg="handleToggleNoResultsMsg"
                    />
                </template>
            </div>
            <div class="right-container" :class="{ hidden: isMobile && !isRightSidebarOpen, blocked: isRightSidebarOpen }">
                <Button
                    class="rightSiderbar-Controler"
                    :class="{ closedSidebar: !isRightSidebarOpen }"
                    :bType="isRightSidebarOpen ? 'rightArrow' : 'leftArrow'"
                    :bCallback="toggleRightSidebar"
                >
                </Button>
                <ReportSidebar
                    :displaySettings="displaySettings"
                    @checkboxChange="updateDisplaySettings"
                    @searchInputChange="updateSearchValue"
                    :showNoResultsMsg="showNoResultsMsg"
                    :isRightSidebarOpen="isRightSidebarOpen"
                    :isMobile="isMobile"
                />
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Report from '@/components/ui/Report'
import ReportActionPlan from '@/components/ui/ReportActionPlan'
import ShareURL from '@/components/ui/ShareURL'
import ReportSidebar from '@/components/ui/ReportSidebar.vue'
import { TYPE } from '@/constants'
import SearchBar from '../../../ui/SearchBar.vue'

export default {
    name: 'ReportAudit',
    props: {
        reports: { type: Array }
    },
    components: {
        Report,
        ShareURL,
        ReportSidebar,
        SearchBar,
        ReportActionPlan
    },
    data() {
        return {
            selectedTab: 'audit',
            isLeftSidebarOpen: false,
            isRightSidebarOpen: true,
            auditWithItems: {},
            printOpts: {
                showing: false,
                attach: true,
                feed: true
            },
            previewOpts: {
                showing: false,
                note: true,
                desc: true
            },
            pagination: {
                page: 1,
                itemsPerPage: 15
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('supervise.audits.table_headers.audit'),
                    align: 'start',
                    sortable: false,
                    value: 'title',
                    width: '30%',
                    class: 'header-table'
                }
            ],
            isReportLogout: false,

            // report display settings
            showAnswers: localStorage.getItem('auditsDisplayNoAnswered') == 'false' ? false : true,
            showDetails: localStorage.getItem('auditsShowDetails') == 'false' ? false : true,
            showNotes: localStorage.getItem('auditsShowNotes') == 'false' ? false : true,
            showNoApply: localStorage.getItem('auditsShowNoApply') == 'false' ? false : true,
            showImagesResume: localStorage.getItem('auditsShowImagesResume') == 'false' ? false : true,
            showPoint: localStorage.getItem('auditsShowPoints') == 'false' ? false : true,

            // search in report
            userFilterSearch: '',
            showNoResultsMsg: false,
            isMobileSearchBarOpen: false,
            // mobile

            windowWidth: window.innerWidth
        }
    },
    computed: {
        items() {
            if (this.reports != undefined) {
                var result = this.$store.getters['audits/getListReports'](this.reports)

                return result
            } else return false
        },

        optionsStates() {
            return this.$store.getters['audits/getFilterStates']()
        },
        isExpanded() {
            return this.$store.state['menu_tools_is_expanded']
        },

        displaySettings() {
            return [
                { type: 'answers', value: this.showAnswers, label: i18n.t('nvgt.report.hide_no_answered') },
                { type: 'details', value: this.showDetails, label: i18n.t('nvgt.report.hide_details') },
                { type: 'show_notes', value: this.showNotes, label: i18n.t('nvgt.report.hide_notes') },
                { type: 'no_apply', value: this.showNoApply, label: i18n.t('nvgt.report.hide_noApply') },
                { type: 'images_resume', value: this.showImagesResume, label: i18n.t('nvgt.report.hide_imageResume') },
                { type: 'show_point', value: this.showPoint, label: i18n.t('nvgt.report.hide_point') }
            ]
        },
        PDFFromServerFF() {
            const PDFFromServerFF = parseInt(this.$store.getters['account/getReportPdf'])
            if (PDFFromServerFF == 1) {
                return true
            }
            return false
        },
        isMobile() {
            return this.windowWidth <= 767
        },
        userIsLogged() {
            return this.$store.getters['login/getHasLogin']
        },
        allItemStatuses() {
            const reports = this.items
            let statuses = []
            if (!reports) return statuses

            Object.values(reports).forEach((report) => {
                if (report.items) {
                    report.items.forEach((item) => {
                        if (item.status) statuses.push(item.status)
                    })
                }
            })

            return statuses
        },
        navTabsLeftStyle() {
            let baseVw
            const screenWidth = window.innerWidth

            if (this.isRightSidebarOpen) {
                if (screenWidth > 1024) {
                    baseVw = 108
                } else if (screenWidth > 768) {
                    baseVw = 119
                } else {
                    baseVw = 95
                }
            } else {
                if (screenWidth > 1024) {
                    baseVw = 98
                } else if (screenWidth > 768) {
                    baseVw = 95
                } else {
                    baseVw = 90
                }
            }
            return `calc(100% - ${baseVw}vw)`
        }
    },
    methods: {
        load() {
            var self = this

            if (this.reports != undefined) {
                var sReportsID = JSON.stringify(this.reports)

                if (!this.$route.params.id) {
                    this.$router.push({
                        name: 'ReportAudit',
                        params: { id: this.reports[0] }
                    })
                }

                var filterLocations = self.$store.getters['filterLocations/getFilterLocation']()
                var filterTemplates = self.$store.getters['audits/getFilterTemplate']()
                var filterStates = self.$store.getters['audits/getFilterStates']()
                console.warn(1)
                if (Object.keys(filterLocations).length > 0 && Object.keys(filterTemplates).length > 0 && Object.keys(filterStates).length > 0) {
                    this.$store.dispatch('audits/loadReportList', { ids: sReportsID }).then(function (response) {})
                    this.$store.dispatch('audits/superviseReportLog', { checklists: this.reports.join() }).then(function (response) {})
                    if (Object.values(self.items).length > 1) {
                        this.isLeftSidebarOpen = true
                        this.isRightSidebarOpen = false
                    }
                } else {
                    console.warn(3)
                    self.$store.dispatch('audits/loadFilters', {}).then(function () {
                        self.$store.dispatch('audits/loadReportList', { ids: sReportsID }).then(function (response) {})
                        self.$store.dispatch('audits/superviseReportLog', { checklists: self.reports.join() }).then(function (response) {})
                    })
                }
            }
        },
        leftTime(valDate) {
            var num = valDate
            var hours = num / 60
            var rhours = Math.floor(hours)
            var minutes = (hours - rhours) * 60
            var rminutes = Math.round(minutes)
            var result = ''
            if (rhours == 0) {
                result = '+' + rminutes + ' min'
            } else if (rhours > 1) {
                result = '+' + rhours + 'h y ' + rminutes + ' min'
                if (rminutes == 0) {
                    result = '+' + rhours + 'horas'
                }
            } else {
                result = '+' + rhours + 'h y ' + rminutes + ' min'
                if (rminutes == 0) {
                    result = '+' + rhours + 'h'
                }
            }
            return result
        },

        getAuditState(state) {
            var result = ''
            if (state == 1) {
                result = 'finalizada'
            }
            if (state == 2) {
                result = 'en_progreso'
            }
            if (state == 3) {
                result = 'programada'
            }
            return result
        },

        changeReport(id) {
            if (id === this.$route.params.id) {
                return
            }

            this.$router.push({ name: 'ReportAudit', params: { id: id } })
            this.$overlay.hide()
        },

        goBack() {
            this.$overlay.show()
            this.$emit('back')

            if (id != this.$route.params.id) {
                this.$router.push({ name: 'ReportAudit', params: { id: id } })
            }
        },

        goBack() {
            this.$emit('back')
        },

        spanishDateFormat(valDate) {
            var localLocale = moment(valDate)
            // moment.locale('es')
            localLocale.locale(false)
            return localLocale.format('DD/MM/YY HH:mm')
        },
        showExportByEmailPopup() {
            const self = this

            const user = self.$store.getters['loginUser/getLocalUser']
            const email = user.email
            const messageWithEmail = self.$t('supervise.reports.export.popup_email.message', { email: `<b>${email}</b>` })
            const messageWithoutEmail = self.$t('supervise.reports.export.popup_email.message2')

            self.$popup.messageWithButton({
                title: self.$t('supervise.reports.export.popup_email.title'),
                message: email ? messageWithEmail : messageWithoutEmail,
                callCancel: () => {
                    self.$popup.close()
                }
            })
        },
        exportAuditPDF() {
            const PDFFromServerFF = this.PDFFromServerFF

            const reportsQuantity = Object.values(this.items).length

            if (PDFFromServerFF || reportsQuantity === 1) {
                const self = this

                const reportsIds = Object.values(self.items).map((report) => report.id)
                self.$store.commit('reports/setReportsToExportIds', reportsIds)

                const options = {
                    answers: this.showAnswers,
                    details: this.showDetails,
                    show_notes: this.showNotes,
                    no_apply: this.showNoApply,
                    images_resume: this.showImagesResume,
                    show_point: this.showPoint
                }

                // const reportsQuantity = reportsIds.length
                // if (reportsQuantity > 50) {
                //     self.$store.dispatch('reports/exportReports', { type: TYPE.TASK, options: options })
                //     self.showExportByEmailPopup()
                // } else {
                // if (reportsQuantity >= 10) {
                //     self.$popup.exportLoading()
                // } else self.$overlay.loading()

                // display loader
                self.$overlay.loading()

                self.$store.dispatch('reports/exportReports', { type: TYPE.AUDIT, options: options }).then((response) => {
                    if (!response.status) {
                        // close popup or loader
                        // if (reportsQuantity >= 10) {
                        //     self.$popup.close()
                        // } else self.$overlay.hide()

                        // close loader
                        self.$overlay.hide()

                        if (response.error.code == -2) {
                            // inform that is not possible to export and ask user to change the filters
                            self.$popup.messageWithButton({
                                title: this.$t('supervise.reports.export.popup_avoid.title'),
                                message: this.$t('supervise.reports.export.popup_avoid.message2'),
                                customClass: 'error',
                                callCancel: () => {
                                    // close popup
                                    self.$popup.close()
                                }
                            })
                        } else {
                            // inform user that PDFs will be sent by email:
                            self.showExportByEmailPopup()
                        }

                        return
                    } else {
                        // close popup or loader
                        // if (reportsQuantity >= 10) {
                        //     self.$popup.close()
                        // } else self.$overlay.hide()

                        // close loader
                        self.$overlay.hide()

                        // open PDFs in a new tab
                        window.open(response.url, '_blank')
                    }
                })
            } else {
                this.$snackbar.close()
                this.$store.dispatch('tasks/supervisePdfPrintlog', { id: this.$route.params.id })
                window.print()
                this.count += 1
                // report print scroll to bottom
                this.$refs.reportPrint.$el.scrollTop = this.$refs.reportPrint.$el.scrollHeight
                setTimeout(() => {
                    window.print()
                }, 10)
                this.$refs.reportPrint
            }
        },
        printActionPlanReportFromBrowser() {
            window.print()
        },
        handleSaveAsPDF() {
            if (this.selectedTab === 'audit') {
                this.exportAuditPDF()
            } else {
                this.printActionPlanReportFromBrowser()
            }
        },
        updateDisplaySettings(params) {
            const { type, value } = params

            if (type === 'answers') {
                this.showAnswers = value
                localStorage.setItem('auditsDisplayNoAnswered', value)
                return
            }

            if (type === 'details') {
                this.showDetails = value
                localStorage.setItem('auditsShowDetails', value)
                return
            }

            if (type === 'show_notes') {
                this.showNotes = value
                localStorage.setItem('auditsShowNotes', value)
                return
            }

            if (type === 'no_apply') {
                this.showNoApply = value
                localStorage.setItem('auditsShowNoApply', value)
                return
            }

            if (type === 'images_resume') {
                this.showImagesResume = value
                localStorage.setItem('auditsShowImagesResume', value)
                return
            }

            if (type === 'show_point') {
                this.showPoint = value
                localStorage.setItem('auditsShowPoints', value)
                return
            }
        },

        updateSearchValue(value) {
            if (!value) {
                this.userFilterSearch = ''
            }

            this.userFilterSearch = value
        },
        handleToggleNoResultsMsg(value) {
            this.showNoResultsMsg = value
        },
        toggleLeftSidebar() {
            this.isLeftSidebarOpen = this.isLeftSidebarOpen ? false : true
            if (this.isLeftSidebarOpen) {
                this.isRightSidebarOpen = false
            }
        },
        toggleRightSidebar() {
            this.isRightSidebarOpen = !this.isRightSidebarOpen
            if (this.isRightSidebarOpen) {
                this.isLeftSidebarOpen = false
            }
        },

        handleResize() {
            this.windowWidth = window.innerWidth
        },

        selectTab(tabName) {
            this.selectedTab = tabName
        },

        itemsWithActionPlans() {
            const itemsContainer = Object.values(this.items)[0]

            if (itemsContainer && itemsContainer.data.actionplans_quantity !== undefined) {
                return itemsContainer.data.actionplans_quantity
            } else {
                // console.log('There is no item or actionplans_quantity is undefined')
                return 0
            }
        },
        getReportStatus(score) {
            if (score === 0) {
                return this.$t('nvgt.t1.status.notStart')
            } else if (score < 100) {
                return this.$t('nvgt.t1.status.current')
            } else if (score == 100) {
                return this.$t('nvgt.t1.status.past')
            } else {
                return '-'
            }
        }
    },
    watch: {
        userFilterSearch(newSearch, old) {
            if (newSearch.length < 1) {
                this.showNoResultsMsg = false
            }
        },
        isMobile(val) {
            this.isRightSidebarOpen = !val
        }
    },
    created() {
        this.load()
        this.isReportLogout = this.$store.getters['records/getReportAccess'] ? true : false
        if (this.$store.getters['login/getHasLogin']) {
            this.isReportLogout = true
        }

        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        // if (this.isMobile) {
        //     this.isRightSidebarOpen = false
        // }
        this.isRightSidebarOpen = !this.isMobile
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style lang="scss">
#content.report-audit {
    padding: 0;
    .nav-tabs {
        position: relative;
        display: grid;
        place-items: center;
        top: 8px;
        padding: 8px 0px;
        left: calc(100% - 98vw);

        @media (min-width: 776px) {
            left: calc(100% - 103vw);
            top: 8px;
        }
        @media (min-width: 901px) {
            left: calc(100% - 96vw);
            top: 8px;
        }

        @media (min-width: 1024px) {
            left: calc(100% - 98vw);
            top: 54px;
        }

        .tabs {
            border: 1px solid $color-neutral-300;
            background-color: $color-white;
            width: 100%;
            max-width: 400px;
            height: 50px;
            border-radius: 4px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 4px;
            gap: 4px;

            @media (min-width: 768px) {
                width: 400px;
                max-width: 400px;
            }

            .tab {
                width: 100%;
                height: 100%;
                height: 34px;
                border-radius: 4px;
                background-color: $color-white;
                font-size: 16px;
                line-height: 19.12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 2px;
                position: relative;
                max-height: 100%;
                margin: 0px;
                height: 40px;

                .title {
                    font-weight: bold;
                    font-size: 14px !important;
                    line-height: 1rem;
                    .selected {
                        background-color: $color-secondary-100;
                        color: $color-secondary-500;
                    }
                }
                .text {
                    font-size: 14px;
                    padding: 0;

                    .status {
                        font-size: 14px;
                    }
                    .selected {
                        background-color: $color-secondary-100;
                        color: $color-secondary-500;
                    }
                }
                .items-number {
                    position: absolute;
                    background-color: $color-error-500;
                    color: $color-white;
                    top: 5px;
                    right: 4px;
                    border-radius: 50%;
                    font-size: 14px;
                    display: grid;
                    place-items: center;
                    width: 32px;
                    height: 31px;
                }
            }

            .action-plan {
                .selected {
                    background-color: $color-secondary-100;
                    color: $color-secondary-500;
                }
            }

            button:hover {
                background-color: $color-white;
            }

            .selected {
                background-color: $color-secondary-100;
                color: $color-secondary-500;
            }

            .selected:hover {
                background-color: $color-secondary-100;
            }
        }

        @media print {
            .tabs {
                display: none;
            }
        }
    }
    > .report-main-container {
        padding-top: 0px;
        position: absolute;
        width: 100%;
        top: 73px;
        display: flex;
        justify-content: space-between;

        .report-container {
            @media (max-width: 767px) {
                height: calc(100vh - 114px);
            }
        }

        .left-container {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .sidebar-left-data-table {
                height: calc(100% - 70px);
            }

            .leftSiderbar-Controler {
                margin-top: 16px;
                margin-left: -24px;
                height: 32px;
                background-position: 0px center;
                background-size: 18px;
                background-color: transparent;
                z-index: 2;
            }

            .closedSidebar {
                margin-left: -6px;
                margin-right: 0px;
            }
        }

        .closedLeftSidebar {
            width: 12px;
            background-color: $color-white;
            margin-left: 8px;
            box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
        }

        .main-content {
            width: 100%;
            max-width: 100%;

            @media (min-width: 1160px) {
                left: 0;
                width: calc(100% - 308px);
            }
        }

        .right-container {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .rightSiderbar-Controler {
                margin-top: 16px;
                margin-right: 272px;
                height: 32px;
                background-position: 0px center;
                background-size: 18px;
                background-color: transparent;
                z-index: 2;
            }

            .closedSidebar {
                margin-right: 6px;
            }
        }

        .small-width {
            // width: calc(100% - 656px);
            width: calc(100% - 366px);
            left: 0;
        }
    }
}
</style>
